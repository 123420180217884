import moment from "moment";
import { Status } from '@/constants/Order';

export class OrderContract {
    productId: number;
    productName: string;
    productImage: string;
    productCode: string;
    quantity: number;
    delivery1: OrderContractItem;

    constructor(productId: number, productCode: string, productName: string, productImage: string,
                quantity?: number, deliveryDate?: string) {
        this.productId = productId;
        this.productCode = productCode;
        this.productName = productName;
        this.productImage = productImage;
        this.quantity = quantity ? quantity : 1;
    }
}

export class OrderContractItem {
    quantity: number;
    deliveryDate?: any;
    status: Status;
    orderId?: string;

    constructor(quantity?: number, deliveryDate?: any, status?: Status, orderId?: string) {
        this.quantity = quantity ? quantity : 0;
        this.deliveryDate = deliveryDate ? deliveryDate : moment().format();
        this.status = status ? status : Status.Draft;
        this.orderId = orderId;
    }
}