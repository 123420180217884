














import {Component, Ref, Vue} from "vue-property-decorator";
import {OrderContract, OrderContractItem} from "@/views/order/order-contract/OrderContact";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

@Component({
    components: {
        flatPickr
    }
})
export default class OrderContractCellEditor extends Vue {
    @Ref('inputElement')
    inputElement!: any;
    configDateTime = {
        enableTime: true,
        dateFormat: 'd-m-Y H:i'
    };
    params: any = null;
    numberOfDelivery = 0;
    orderContract: OrderContract;
    orderContractItem: OrderContractItem = new OrderContractItem();

    // orderDateClosed(selectedDates: any, dateStr: any, instance: any) {
    //     // let start = selectedDates[0];
    //     // let formatType = 'DD-MM-YYYY HH:mm';
    //     // this.orderContractItem.deliveryDate = moment(start).format();
    // }

    get isRowPinned() {
        return this.params.node.isRowPinned();
    }

    get getPlaceholder() {
        return this.$t('Select date');
    }

    mounted() {
        let formatType = 'DD-MM-YYYY HH:mm';
        this.orderContractItem = this.params.value;
        let deliveryDate = this.orderContractItem.deliveryDate;
        // this.orderContractItem.deliveryDate = deliveryDate ? moment(deliveryDate).format(formatType)  : new Date();

        let dateFormatted = moment(deliveryDate).format(formatType);

        if (dateFormatted == "Invalid date") {
            this.orderContractItem.deliveryDate = deliveryDate;
        }
        else{
            this.orderContractItem.deliveryDate = dateFormatted;
        }

        this.orderContract = this.params.data;
        this.numberOfDelivery = this.params.numberOfDelivery;
        if (!this.isRowPinned) {
            setTimeout(() => {
                this.inputElement.focus();
                this.inputElement.select();
            }, 50);
        }
    }

    onBlur() {
        const data: any = this.params.data;

        if (this.numberOfDelivery > 0) {
            let totalUserInput = 0;
            for (let i = 1; i <= this.numberOfDelivery; i++) {
                let quantity = data[`delivery${i}`].quantity;
                quantity = quantity ? quantity : 0;
                totalUserInput += quantity;
            }
            if (totalUserInput > data.quantity) {
                this.$vs.notify({
                    title: "Thông báo",
                    text: "Số sản phẩm trên mỗi lần giao không được phép lớn hơn tổng số sản phẩm.",
                    color: "danger"
                })
            }
            data.remainQuantity = data.quantity - totalUserInput;
        }
    }

    getValue(): any {
        return this.orderContractItem;
    }

    // isPopup() {
    //
    // }
    //
    // isCancelBeforeStart() {
    //
    // }
    //
    // isCancelAfterEnd() {
    //
    // }
    //
    // afterGuiAttached() {
    //
    // }
}
