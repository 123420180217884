











import {Component, Vue} from "vue-property-decorator";
import moment from 'moment';

@Component
export default class OrderContractPinnedRowRenderer extends Vue {
    params: any;

    get isPinnedTop() {
        const rowPinned = this.params.node.rowPinned;
        return rowPinned && rowPinned === 'top'
    }

    // get isPinnedBottom() {
    //     const rowPinned = this.params.node.rowPinned;
    //     return rowPinned && rowPinned === 'bottom'
    // }
    //
    // get showButton() {
    //     return this.params.value && this.params.value.quantity > 0;
    // }

    get deliveryDate(){
        let date = this.params.value.deliveryDate;

        if (!date) {
            return '';
        }

        let dateFormatted = moment(date).format('DD/MM/YYYY HH:mm');

        if (dateFormatted == "Invalid date") {
            return date;
        }

        return dateFormatted;
    }

    createDeliveryPlan() {
    }
}
