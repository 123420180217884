









































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {OrderService} from '@/views/order/OrderService';
import _head from "lodash/head";
import _each from "lodash/each";
import _filter from "lodash/filter";

import {Status} from "@/constants/Order";
import {OptionOrderStatus} from '@/models/order/OrderSearchResult';

@Component({})
export default class DeliveryTimeHeaderComponent extends Vue{
    params: any;

    private orderService: OrderService;

    constructor(){
        super();

        this.orderService = new OrderService();
    }

    orderStatus = Status;

    onDelete(){
        let orderId = "";
        let colId = this.params.column.colId;
        let field: string = this.params.column.colDef.field;

        if (this.deliveryTime) {
           orderId = this.deliveryTime.orderId;
        }

        let deliveryPlan: any = _head(this.params.deliveryPlanItems);

        if (deliveryPlan) {
            let timeGoodsIssued = _filter(deliveryPlan.deliveryTimes, (deliveryTime: any) => {
                return deliveryTime.status === Status.GoodsIssued;
            }).length;

            let numberOfDelivery = this.params.numberOfDelivery;
            numberOfDelivery = numberOfDelivery -1;

            if (timeGoodsIssued > 0) {
                if (numberOfDelivery <= timeGoodsIssued) {
                    this.$vs.notify({
                        title: '',
                        text: "Số lần giao không thể nhỏ hơn hoặc bằng tổng số đơn hàng đã xuất kho!",
                        color: "danger"
                    });

                    return;
                }
            }
        }

        // if (!orderId) {
            _each(this.params.deliveryPlanItems, (item: any) => {
                this.$delete(item, field);
            });

            this.params.column.columnApi.setColumnVisible(colId, false);
            this.params.numberOfDeliveryCallback(function() { });

        //     return;
        // }

        // this.$vs.dialog({
        //     type: 'confirm',
        //     color: 'danger',
        //     title: `Xác nhận`,
        //     acceptText: this.$t('Accept'),
        //     cancelText: this.$t('Cancel'),
        //     text: `Bạn có muốn xóa kế hoạch giao hàng không?`,
        //     accept: () => {
        //         // this.delete(orderId, colId, field);
        //
        //         _each(this.params.deliveryPlanItems, (deliveryPlan : any) => {
        //             let index = parseInt(field.slice(8));
        //
        //             this.$delete(deliveryPlan.deliveryTimes, index - 1);
        //         });
        //
        //         this.params.column.columnApi.setColumnVisible(colId, false);
        //         this.params.numberOfDeliveryCallback(function() { });
        //     },
        //     cancel: () => {
        //         // this.save(Status.TotalOrder);
        //     }
        // });
        //
        // return;
    }

    async delete(orderId: string, colId: string, field: string){
        if (orderId) {
            let result = await this.orderService.deleteDeliveryPlan(orderId);

            if (result.code > 0) {
                this.params.column.columnApi.setColumnVisible(colId, false);
                this.params.numberOfDeliveryCallback(function() { });

                this.$vs.notify({
                    title: '',
                    text: "Xóa kế hoạch giao hàng thành công!",
                    color: "success"
                });
            }
        }
    }

    get deliveryTime(){
        let colId = this.params.column.colDef.field;

        if (colId && this.params.deliveryPlanItems && this.params.deliveryPlanItems.length > 0) {
            let deliveryPlanItem: any = _head(this.params.deliveryPlanItems);

            if (deliveryPlanItem) {
                let deliveryTime = deliveryPlanItem[colId];

                if (deliveryTime) {
                    return deliveryTime;
                }
            }
        }

        return null;
    }

    get status(){
        if (this.deliveryTime) {
            return this.deliveryTime.status;
        }
        else{
            return 0;
        }
    }

    set status(value: Status){
        if (this.deliveryTime) {
            this.deliveryTime.status = value;
        }
    }

    get chipColor() {
        return (value: any) => {
            return new OptionOrderStatus(value).color;
        }
    }

    get statusName() {
        return (value: any) => {
            return new OptionOrderStatus(value).name;
        }
    }

    async updateStatus(status: Status){
        let orderId = "";

        if (this.deliveryTime) {
           orderId = this.deliveryTime.orderId;
        }

        if (orderId) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận`,
                acceptText: this.$t('Accept'),
                cancelText: this.$t('Cancel'),
                text: `Bạn có muốn cập nhật trạng thái không?`,
                accept: async () => {
                    if (orderId) {
                        let result = await this.orderService.updateStatus(orderId, status);
                        this.status = status;

                        if (result.code > 0) {
                            this.$vs.notify({
                                title: '',
                                text: "Cập nhật trạng thái thành công!",
                                color: "success"
                            });
                        }
                    }
                }
            });
        }
        else{
            this.status = status;
        }
    }
}
