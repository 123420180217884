




import {Component, Vue} from "vue-property-decorator";
import {formatNumber} from "@/common";

@Component
export default class OrderContractQuantityCellRenderer extends Vue {
    params: any = null;

    get quantity(){
        return formatNumber(this.params.value.quantity);
    }
}
