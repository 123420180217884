













































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {OrderDetail} from "@/models/order/OrderDetail";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {AgGridVue} from "ag-grid-vue";

import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _head from 'lodash/head';
import _filter from 'lodash/filter';
import _each from 'lodash/each';
import _sumBy from 'lodash/sumBy';

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import {OrderContractItem} from "@/views/order/order-contract/OrderContact";
import OrderContractPinnedRowRenderer
    from '@/views/order/order-contract/cells/OrderContactPinnedRowRenderer.vue';
import OrderContractQuantityCellRenderer
    from '@/views/order/order-contract/cells/OrderContractQuantityCellRenderer.vue';
import OrderContractCellEditor
    from "@/views/order/order-contract/cells/OrderContractCellEditor.vue";
import OrderContractPinnedRowEditor from "@/views/order/order-contract/cells/OrderContractPinnedRowEditor.vue";
import { Status } from '@/constants/Order';
import {OrderService} from '@/views/order/OrderService';
import DeliveryTimeHeaderComponent from "@/views/order/order-contract/header/DeliveryTimeHeaderComponent.vue";
import CustomPinnedRowRenderer from "@/views/order/order-list/CustomPinnedRowRenderer.vue";
import {formatNumber} from "@/common";
import moment from 'moment';

@Component({
    components: {
        VuePerfectScrollbar,
        AgGridVue,
        DeliveryTimeHeaderComponent
    }
})
export default class OrderContractList extends Vue {
    @Ref('numberOfDeliveryElement') numberOfDeliveryElement!: any;
    @Prop({default: () => []}) data!: OrderDetail[];
    @Prop() orderId!: string;
    @Prop() deliveryDate!: any;

    @Watch('numberOfDelivery')
    onNumberOfDelivery(value: number, oldValue: number) {
        if (oldValue === 0 && this.isUpdate) {
            return;
        }

        let deliveryPlan: any = _head(this.rowData);

        if (deliveryPlan) {
            let timeGoodsIssued = _filter(deliveryPlan.deliveryTimes, (deliveryTime: any) => {
                return deliveryTime.status === Status.GoodsIssued;
            }).length;

            if (timeGoodsIssued > 0) {
                if (value <= timeGoodsIssued) {
                    this.$vs.notify({
                        title: '',
                        text: "Số lần giao không thể nhỏ hơn hoặc bằng tổng số đơn hàng đã xuất kho!",
                        color: "danger"
                    });

                    return;
                }
            }
        }

        this.oldNumberOfDelivery = oldValue;
        this.renderColumnDefs(this.numberOfDelivery);
        this.renderRowPinnedData();
        this.renderRowData();
    }

    @Watch('isShow')
    onShown(value: boolean) {
        if (value) {
            setTimeout(() => {
                this.numberOfDeliveryElement.$el.querySelector('input').focus();
            }, 100);

            this.listOrderContract;
            this.renderRowData();
        }
    }

    private orderService: OrderService;

    constructor(){
        super();

        this.orderService = new OrderService();
    }

    isUpdate: boolean = false;
    orderStatus = Status;
    isShow = false;
    numberOfDelivery: number = 0;
    oldNumberOfDelivery: number = 0;
    settings: any = {
        maxScrollbarLength: 60,
        wheelSpeed: 0.70,
    };
    gridColumnApi: any = null;
    gridApi: any = null;
    gridOptions: any = null;
    columnDefs: any = null;
    defaultColDef: any = null;
    rowPinnedData: any = [];
    // pinnedBottomRowData: any = [{productName: 'Chức năng'}];
    radios1: any = 1;

    initialColumnsDef = [
        {
            headerName: "Tên sản phẩm",
            field: "productName",
            width: 150
        },
        {
            headerName: 'Tổng số lượng',
            field: 'quantity',
            width: 150,
            cellRenderer: (data: any) => {
                return formatNumber(data.value)
            },
            pinnedRowCellRenderer: "CustomPinnedRowRenderer"
        },
        {
            headerName: 'Còn lại',
            field: 'remainQuantity',
            width: 150,
            cellRenderer: (data: any) => {
                return formatNumber(data.value)
            },
            pinnedRowCellRenderer: "CustomPinnedRowRenderer"
        },
    ];

    components: any = {};

    frameworkComponents: any = {
        orderContractQuantityCellRenderer: OrderContractQuantityCellRenderer,
        orderContractPinnedRowRenderer: OrderContractPinnedRowRenderer,
        orderContractPinnedRowEditor: OrderContractPinnedRowEditor,
        orderContractCellEditor: OrderContractCellEditor,
        CustomPinnedRowRenderer
    };

    rowData: any = [];

    get listOrderContract() {
        this.rowData = this.data.map((orderDetail: OrderDetail) => {
            return {
                productId: orderDetail.product.id,
                productName: orderDetail.product.name,
                productCode: orderDetail.product.code,
                productImage: orderDetail.product.image,
                deliveryTimes: [],
                quantity: orderDetail.quantity
            }
        });

        return this.rowData;
    }

    set listOrderContract(value: any){
        this.rowData = value;
    }

    beforeMount() {
        this.gridOptions = {
            localeText: {
                noRowsToShow: 'Không có dữ liệu',
            }
        };
        this.columnDefs = _cloneDeep(this.initialColumnsDef);
        this.defaultColDef = {};
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    async show(isUpsert: boolean = false) {
        this.isShow = true;
        this.isUpdate = false;
        // this.numberOfDelivery = 0;

        if (isUpsert && this.orderId) {
            this.isUpdate = isUpsert;
            let result = await this.orderService.getDeliveryPlans(this.orderId);

            if(result.code > 0){
                let deliveryPlans = result.data;

                if (deliveryPlans && deliveryPlans.length > 0) {
                    deliveryPlans.forEach((deliveryPlan: any) => {
                        if (!this.numberOfDelivery) {
                            this.numberOfDelivery = deliveryPlan.deliveryTimes.length;
                        }

                        for (let i = 1; i <= this.numberOfDelivery; i++) {
                            let deliveryTimeData = deliveryPlan.deliveryTimes[i-1];

                            if (deliveryTimeData) {
                                let dateFormatted = moment(deliveryTimeData.deliveryDate).format('DD/MM/YYYY HH:mm');

                                deliveryPlan[`delivery${i}`] = {
                                    quantity: deliveryTimeData.quantity,
                                    deliveryDate: dateFormatted,
                                    orderId: deliveryTimeData.orderId,
                                    status: deliveryTimeData.status
                                };
                            }
                        }

                        let orderContract = _find(this.listOrderContract, (item) => {
                            return item.productId === deliveryPlan.productId;
                        });

                        if (orderContract) {
                            deliveryPlan.quantity = orderContract.quantity;
                        }
                    });

                    this.listOrderContract = deliveryPlans;
                    const data: any = {productName: '', quantity: '', remainQuantity: ''};
                    let deliveryPlan: any = _head(deliveryPlans);

                    if (deliveryPlan && deliveryPlan.deliveryTimes) {
                        for (let i = 1; i <= this.numberOfDelivery; i++) {
                            let deliveryPlanData = deliveryPlan.deliveryTimes[i-1];

                            if (deliveryPlanData) {
                                let dateFormatted = moment(deliveryPlanData.deliveryDate).format('DD/MM/YYYY HH:mm');

                                data[`delivery${i}`] = {
                                    // quantity: deliveryPlanData.quantity,
                                    deliveryDate: dateFormatted,
                                    status: deliveryPlanData.status
                                };
                            }
                        }

                        this.rowPinnedData = [];
                        this.rowPinnedData.push(data);
                    }

                    this.renderColumnDefs(this.numberOfDelivery);
                }
                // else{
                //     this.isUpdate = false;
                // }
            }
        }
    }

    async accept(status: Status) {
        const listOrderDetail = this.convertToDataRequest(this.rowData, status);

        if (!listOrderDetail || listOrderDetail.length === 0) {
            return;
        }

        if (this.orderId) {
            try {
                this.openLoading(true);
                var result = await this.orderService.insertDeliveryPlans(this.orderId, listOrderDetail);

                if (result.code > 0) {
                    this.openLoading(false);

                    this.$vs.notify({
                        title: '',
                        text: "Cập nhật kế hoạch giao hàng thành công!",
                        color: "success"
                    });

                    this.isShow = false;
                }
            } catch (error) {
                this.openLoading(false);

                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: 'danger'
                });
            }
        }
        else{
            this.$emit('accepted', listOrderDetail, status);
            this.isShow = false;
        }
    }

    async update(){
        let data = this.convertToDataRequest(this.rowData);

        if (!data || data.length === 0) {
            return
        }

        try {
            this.openLoading(true);

            _each(data, (item: any) => {
                delete item.deliveryDate;
            });

            let result = await this.orderService.updateDeliveryPlans(this.orderId, data);

            if (result.code > 0) {
                this.openLoading(false);
                this.isShow = false;

                this.$vs.notify({
                    title: '',
                    text: "Cập nhật kế hoạch giao hàng thành công!",
                    color: "success"
                });
            }
        } catch (error) {
            this.openLoading(false);

            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        }
    }

    get isEditNumberDelivery(){
        let isGoodsIssued = false;

        _each(this.rowData, (item: any, i: number) => {
            let fieldName = `delivery${i + 1}`;

            let data = item[fieldName];

            if (data) {
                isGoodsIssued = data.status === Status.GoodsIssued;
            }
        });

        return isGoodsIssued;
    }

    private renderColumnDefs(numberOfDelivery: number) {
        this.columnDefs = _cloneDeep(this.initialColumnsDef);

        for (let i = 1; i <= numberOfDelivery; i++) {
            this.columnDefs.push({
                coldId: `col_delivery${i}`,
                headerName: `Lần ${i}`,
                field: `delivery${i}`,
                cellRenderer: 'orderContractQuantityCellRenderer',
                cellEditor: 'orderContractCellEditor',
                pinnedRowCellRenderer: 'orderContractPinnedRowRenderer',
                editable: function (params: any) {
                    let field = params.colDef.field;

                    if (field) {
                        let deliveryTime = params.data[field];

                        if (deliveryTime && deliveryTime.status === Status.GoodsIssued) {
                            return false;
                        }
                    }

                    return !(params.node.isRowPinned() && params.node.rowPinned === "bottom");
                },
                width: 150,
                cellEditorParams: {
                    numberOfDelivery: numberOfDelivery
                },
                headerComponentFramework: DeliveryTimeHeaderComponent,
                headerComponentParams: {
                    deliveryPlanItems: this.rowData,
                    isUpdate: this.isUpdate,
                    numberOfDeliveryCallback: this.numberOfDeliveryCallback,
                    numberOfDelivery: this.numberOfDelivery
                }
            })
        }
    }

    numberOfDeliveryCallback(data: any){
        this.numberOfDelivery--;
    }

    private renderRowData() {
        _each(this.rowData, (item: any, i: number) => {
            // Tính số lượng sản phẩm của đơn có trạng thái đã xuất.
            let deliveryTimes = item.deliveryTimes;

            item.quantityGoodsIssued = _sumBy(deliveryTimes, (deliveryTime: any) => {
                if (deliveryTime.status === Status.GoodsIssued) {
                    return deliveryTime.quantity;
                }
                else{
                    return 0;
                }
            });

            // Đếm số lần đã giao của sản phẩm.
            item.timeGoodsIssued = _filter(deliveryTimes, (deliveryTime: any) => {
                return deliveryTime.status === Status.GoodsIssued;
            }).length;

            // Xóa lần cũ.
            for (let i = 0; i < this.oldNumberOfDelivery; i++) {
                let deliveryTimeName = `delivery${i + 1}`;

                if (item) {
                    this.$delete(item, deliveryTimeName);
                }
            }

            for (let index = this.numberOfDelivery; index < this.oldNumberOfDelivery; index++) {
                this.$delete(item.deliveryTimes, index);
            }
        });

        this.rowData.forEach((orderContract: any) => {
            // Tính số lượng sản phẩm của mỗi lần xuất.
            for (let i = 1; i <= this.numberOfDelivery; i++) {
                let deliveryTimeName = `delivery${i}`;
                let totalProductQuantity = orderContract.quantity - orderContract.quantityGoodsIssued;
                let numberOfDelivery = this.numberOfDelivery - orderContract.timeGoodsIssued;

                // Tính số lượng trên mỗi lần xuất.
                let quantity = Math.floor(totalProductQuantity / numberOfDelivery);
                const remaining = totalProductQuantity % numberOfDelivery;

                // Nếu đơn hàng cuối cùng mà có dư số lượng sản phẩm.
                if (remaining && i === numberOfDelivery) {
                    quantity += remaining;
                }

                let deliveryTime = orderContract.deliveryTimes[i - 1];

                if (deliveryTime) {
                    let status = deliveryTime.status;

                    if (status === Status.GoodsIssued) {
                        quantity = deliveryTime.quantity;
                    }

                    this.$set(orderContract, deliveryTimeName, new OrderContractItem(quantity, deliveryTime.deliveryDate, status, deliveryTime.orderId));
                }
                else{
                    this.$set(orderContract, deliveryTimeName, new OrderContractItem(quantity));
                }
            }
        });

        // this.gridApi.refreshCells();
    }

    private renderRowPinnedData() {
        let data = _head(this.rowPinnedData) as any;

        if (!data) {
            data = {productName: '', quantity: '', remainQuantity: ''};
        }

        // Duyệt qua số lần giao hàng để tạo top pinned row.
        for (let i = 1; i <= this.numberOfDelivery; i++) {
            let deliveryTimeName = `delivery${i}`;

            if (this.rowPinnedData[0] && this.rowPinnedData[0][deliveryTimeName]) {
                continue;
            }

            data[deliveryTimeName] = new OrderContractItem();
        }

        this.rowPinnedData = [];
        this.rowPinnedData.push(data);
    }

    /**
     * Lấy dữ liệu truyền lên server.
     * @param items
     * @param status
     */
    private convertToDataRequest(items: any, status: Status = Status.Draft){
        const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

        items.forEach((orderContract: any) => {
            orderContract.status = status;
            const rowPinnedData = this.rowPinnedData[0];

            for (let i = 1; i <= this.numberOfDelivery; i++) {
                let deliveryTimeName = `delivery${i}`;
                const orderContractItem = orderContract[deliveryTimeName];
                let deliveryDate: any = null;
                let date = rowPinnedData[deliveryTimeName].deliveryDate;

                if (moment(date, DATE_TIME_FORMAT).isValid()) {
                    deliveryDate = moment(date, DATE_TIME_FORMAT).format()
                }
                else{
                    deliveryDate = rowPinnedData[deliveryTimeName].deliveryDate;
                }
                
                let deliveryTime = orderContract.deliveryTimes[i - 1];

                if (deliveryTime) {
                    deliveryTime.quantity = orderContractItem.quantity;
                    deliveryTime.deliveryDate = deliveryDate;
                }
                else{
                    orderContract.deliveryTimes.push({
                        quantity: orderContractItem.quantity,
                        deliveryDate: deliveryDate
                    });
                }
            }
        });

        if (!this.validateDeliveryPlans(this.rowData)) {
            return [];
        }

        return items;
    }

    /**
     * Kiểm tra dữ liệu truyền lên hợp lệ hay không
     * @param deliveryPlans
     */
    private validateDeliveryPlans(deliveryPlans: any) : boolean {
        let isValid = true;

        _each(deliveryPlans, (item: any, i: number) => {
            // Tính số lượng sản phẩm của đơn có trạng thái đã xuất.
            let deliveryTimes = item.deliveryTimes;

            // Số lượng sản phẩm của các lần.
            let sumProductQuantityOfDeliveryPlan = _sumBy(deliveryTimes, (deliveryTime: any) => {
                return deliveryTime.quantity;
            });

            // Số lượng sản phẩm của đơn hàng tổng.
            let quantity = item.quantity;

            if (sumProductQuantityOfDeliveryPlan !== quantity) {
                this.$vs.notify({
                    title: '',
                    // text: "Số lượng đơn tổng chưa được phân bổ hết, bạn vui lòng chọn lại số lần giao!",
                    text: `Số lượng sản phẩm "${item.productName}" phần bổ chưa đúng, bạn vui lòng chọn lại số lần giao!`,
                    color: "danger"
                });

                isValid = false;

                return false;
            }
        });

        return isValid;
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }
}
